function getStateViewURL(object) {
    var value;
    Object.keys(object).some(function(k) {
        if (k === 'templateUrl') {
            value = object[k];
            return true;
        }
        if (object[k] && typeof object[k] === 'object') {
            value = getStateViewURL(object[k]);
            return value !== undefined;
        }
    });
    return value;
}

/*
 * Retorna a próxima URL a ser aberta
 *
 * $objLocation - Objeto $location
 * $objNewState - newState referente ao 'novo' $State a ser carregado.
 *
 */
var getNextURL = function($objLocation, $objNewState){
	let nextURL = $objLocation.$$absUrl.split("#")[0];
	let sName = $objNewState.name;

	if(sName == 'home.calendarioAulas'){
		sName = 'home/aulas';
	}

	if(sName == 'home.tarefas'){
		sName = 'home/tarefa-list';
	}

	while(sName.indexOf('.') > -1){
		sName = sName.replace(".", "/");
	}

	nextURL += "#/"+ sName;

	//console.log(nextURL);

	return nextURL;
};

var pageController;
window.hostApp = window.hostApp || (window.location.origin + '/' + window.location.pathname.split("/")[1] + '/')

var app = (function() {
    return angular.module('MyApp', [
        'ionic',
        'ui.router',
        'ui-notification',
        'ngCookies',
        'pascalprecht.translate',
        'tmh.dynamicLocale',
        'ngResource',
        'login.service',
        'oc.lazyLoad',
        'ui.utils.masks'
    ])

    .constant('LOCALES', {
        'locales': {
            'pt_BR': 'Portugues (Brasil)',
            'en_US': 'English'
        },
        'preferredLocale': 'pt_BR'
    })

    .run(function($ionicPlatform) {
        $ionicPlatform.ready(function() {
            if (window.StatusBar) {
                StatusBar.styleDefault();
            }
        });
    })

    .config(function($controllerProvider, $httpProvider, $ionicConfigProvider) {
        $ionicConfigProvider.navBar.alignTitle('center');
        $ionicConfigProvider.backButton.text('Voltar');
        $httpProvider.interceptors.push('InterceptorFactory');
    })

    .config(function($stateProvider, $urlRouterProvider, $ocLazyLoadProvider, NotificationProvider) {
        $ocLazyLoadProvider.config({
            'debug': false
        });

        NotificationProvider.setOptions({
            delay: 5000,
            startTop: 20,
            startRight: 10,
            verticalSpacing: 20,
            horizontalSpacing: 20,
            positionX: 'center',
            positionY: 'top'
        });

        var $log =  angular.injector(['ng']).get('$log');

         $stateProvider.state('login', {
            cache: true,
            url: "/login",
            controller: 'LoginController',
            templateUrl: function(){ return 'views/login.view.html'; }
        })

        .state('recuperarSenha', {
            cache: false,
            url: "/resetsenha",
            controller: 'RecuperarSenhaController',
            templateUrl: function(){ return 'views/recuperarsenha.view.html'; }
        })

        .state('redefinirSenha', {
            cache: false,
            url: "/redefinirsenha",
            controller: 'RecuperarSenhaController',
            templateUrl: function(){ return 'views/recuperarsenha.view.html'; }
        })

        .state('recuperarSenhaToken', {
            cache: false,
            url: "/resetsenha/:token",
            controller: 'RecuperarSenhaController',
            templateUrl: function(){ return 'views/recuperarsenha.view.html'; }
        })

        .state('home', {
            cache: false,
            abstract: true,
            url: "/home",
            controller: 'HomeController',
            templateUrl: function(){ return 'views/logged/home.view.html'; },
            resolve: {
              themeLoad: [
                'UserService', function(UserService) {
                    UserService.setTheme();
                }
              ],
              homeLoad: [
                '$ocLazyLoad', function($ocLazyLoad) {
                  return $ocLazyLoad.load(
                      /* inject:libal */ ['_lib/libal-1740076766654.min.js'],
                      {serie: true, insertBefore: 'libal'}
                    ).then(function(){
                      return $ocLazyLoad.load(
                          /* inject:appcc */ ['_app/appcc-1740076766654.min.js'],
                          {serie: true, insertBefore: 'appcc'}
                      );
                    })
                }
              ]
            }
        })

        // Loading
        .state('home.loading', {
            cache: false,
            url: "/loading",
            controller: '',
            views: {
                'mainContent': {
                    controller: '',
                    controllerAs: '',
                    templateUrl: function(){ return 'views/logged/_loading.html'; }
                },
            }
        })

        // Tela de Responsável
        .state('home.responsavel', {
            cache: false,
            pageId: "responsavel",
            url: "/responsavel",
            controller: 'HomeController',
            views: {
                'mainContent': {
                    controller: 'AlunosResponsavelController',
                    controllerAs: 'vm',
                    templateUrl: function(){ return 'views/logged/alunos.responsavel.view.html'; }
                }
            }
        })

        // Cadastro do Aluno
        .state('home.aluno-cadastro', {
            cache: false,
            pageId: "dadoscadastrais",
            url: "/aluno-cadastro",
            controller: 'HomeController',
            views: {
                'mainContent': {
                    controller: 'AlunoCadastroController',
                    controllerAs: 'alunoCadastroCtrl',
                    templateUrl: function(){ return 'views/logged/aluno.cadastro.view.html'; }
                }
            }
        })

        // Componentes comuns do projeto
        .state('home.componentes', {
            cache: false,
            pageId: "componentes",
            url: "/componentes",
            controller: 'HomeController',
            views: {
                'mainContent': {
                    controller: function($scope){
                      $log.debug('Componentes gerais');
                    },
                    templateUrl: function(){ return 'views/logged/componentes.temp.view.html'; }
                }
            }

        })

        // Extrato Financeiro
        .state('home.aluno-extrato', {
            cache: false,
            pageId: "aluno-extrato",
            url: "/aluno-extrato",
            controller: 'HomeController',
            views: {
                'mainContent': {
                    controller: 'AlunoExtratoController',
                    templateUrl: function(){ return 'views/logged/aluno.extrato.view.html'; }
                }
            }
        })

        // Acceptance of contract after Login
       .state('home.aceiteContrato', {
          cache: false,
          pageId: "aceiteContrato",
          url: "/aceiteContrato",
          controller: 'HomeController',
          views: {
            'mainContent': {
              controller: 'AlunoAceiteContratoController',
              templateUrl: function () {
                return 'views/logged/aluno.aceite.contrato.view.html';
              }
            }
          }
        })

        // Avisos
        .state('home.avisos', {
            cache: false,
            pageId: "avisos",
            url: "/avisos",
            controller: 'HomeController',
            views: {
                'mainContent': {
                    controller: 'AlunoAvisosController',
                    controllerAs: 'vm',
                    templateUrl: function(){ return 'views/logged/aluno.avisos.view.html'; }
                }
            }
        })

        // Documentos
        .state('home.documentos', {
            cache: false,
            pageId: "documentos",
            url: "/documentos",
            controller: 'HomeController',
            views: {
                'mainContent': {
                    controller: 'AlunoDocumentosController',
                    controllerAs: 'vm',
                    templateUrl: function(){ return 'views/logged/aluno.documentos.view.html'; }
                }
            }
        })

        // Prorrogação Boletos
        .state('home.ajuste-boleto', {
            cache: false,
            pageId: "ajusteboleto",
            url: "/ajuste-boleto",
            controller: 'HomeController',
            views: {
                'mainContent': {
                    controller: 'AjusteBoletoController',
                    controllerAs: 'vm',
                    templateUrl: function(){ return 'views/logged/ajuste.boleto.html'; }
                }
            }
        })
        // Prorrogação Boletos
        // .state('home.ajuste-boleto', {
        //     cache: false,
        //     pageId: "ajusteboleto",
        //     url: "/ajuste-boleto",
        //     controller: 'HomeController',
        //     views: {
        //         'mainContent': {
        //             controller: 'AjusteBoletoController',
        //             controllerAs: 'vm',
        //             templateUrl: 'views/logged/ajuste.boleto.html'
        //         }
        //     }
        // })

        //Disciplinas Matriculadas
        .state('home.disciplinas', {
            cache: false,
            pageId: "disciplinas",
            url: "/disciplinas",
            controller: 'DisciplinasMatriculadasController',
            views: {
                'mainContent': {
                    controller: 'DisciplinasMatriculadasController',
                    controllerAs: 'vm',
                    templateUrl: function(){ return 'views/logged/aluno.disciplinas.view.html'; }
                }
            }
        })

        // Fórmulas e Critérios de Avaliação
        .state('home.criterios-de-avaliacao', {
            cache: false,
            pageId: "criterios-de-avaliacao",
        	url: "/criterios-de-avaliacao",
            controller: 'HomeController',
            params: {
                dadosDisciplina: null
            },
        	views: {
        		'mainContent': {
        			controller: 'CriteriosDeAvaliacaoController',
        			controllerAs: 'vm',
        			templateUrl: function(){ return 'views/logged/novas-telas/aluno.criterios-de-avaliacao.view.html'; }
        		}
        	}
        })

        .state('home.disciplinas-planodidatico', {
            cache: false,
            pageId: "planodidatico",
            url: "/disciplinas-planodidatico",
            controller: 'DisciplinasMatriculadasController',
            views: {
                'mainContent': {
                    controller: 'PlanoDidaticoController',
                    templateUrl: function(){ return 'views/logged/aluno.plano.view.html'; }
                }
            },
            params: {
                'disciplina': {}
            }
        })

        // Ocorrências
        .state('home.ocorrencias', {
            cache: false,
            pageId: "ocorrencias",
            url: "/ocorrencias",
            controller: 'HomeController',
            views: {
                'mainContent': {
                    controller: 'AlunoOcorrenciasController',
                    controllerAs: 'vm',
                    templateUrl: function(){ return 'views/logged/aluno.ocorrencias.view.html'; }
                }
            }
        })

        // Avaliação Institucional
        .state('home.avaliacaoinstitucional', {
            cache: false,
            pageId: "avaliacaoinstitucional",
            url: "/avaliacao",
            controller: 'HomeController',
            views: {
                'mainContent': {
                    controller: 'AvaliaListagemController',
                    controllerAs: 'vm',
                    templateUrl: function(){ return 'views/logged/avaliacao/avaliacao.questionarios.view.html'; }
                }
            }
        })

        .state('home.avaliacaoinstitucional-questionario-full', {
            cache: false,
            pageId: "avaliacaoinstitucional",
            url: "/avaliacao/questionario-full",
            controller: 'HomeController',
            params: {
                questionario: null
            },
            views: {
                'mainContent': {
                    controller: 'AvaliaQuestionarioFullController',
                    controllerAs: 'vm',
                    templateUrl: function(){ return 'views/logged/avaliacao/avaliacao.questionario-full.view.html'; }
                }
            }
        })

        .state('home.avaliacaoinstitucional-questionario-singular', {
            cache: false,
            pageId: "avaliacaoinstitucional",
            url: "/avaliacao/questionario-singular",
            controller: 'HomeController',
            params: {
                questionario: null
            },
            views: {
                'mainContent': {
                    controller: 'AvaliaQuestionarioSingularController',
                    controllerAs: 'vm',
                    templateUrl: function(){ return 'views/logged/avaliacao/avaliacao.questionario-singular.view.html'; }
                }
            }
        })
        
        // Boletim
        .state('home.boletim', {
            cache: false,
            pageId: "boletim",
            url: "/boletim",
            controller: 'HomeController',
            views: {
                'mainContent': {
                    controller:  'AlunoBoletimHomeController',
                    controllerAs: 'vm',
                    templateUrl: function(){ return 'views/logged/aluno.boletim.home.view.html'; }
                }
            }
        })

        .state('home.boletim.resumo', {
            cache: false,
            pageId: "boletim",
            url: "/notas-e-faltas",
            controller: 'HomeController',
            views: {
                'boletimViews': {
                    controller: 'AlunoBoletimV2Controller',
                    controllerAs: 'vm',
                    templateUrl: function(){ return 'views/logged/aluno.boletim.resumo.view.html'; }
                }
            }
        })

        .state('home.boletim.notas', {
            cache: false,
            pageId: "boletim",
            url: "/notas",
            controller: 'HomeController',
            views: {
                'boletimViews': {
                    controller: 'AlunoBoletimV2NotasController',
                    controllerAs: 'vm',
                    templateUrl: function(){ return 'views/logged/aluno.boletim.notas.view.html'; }
                }
            }
        })

        .state('home.fia-boletim', {
            cache: false,
            pageId: "fia-boletim",
            url: "/notas-frequencia",
            controller: 'HomeController',
            views: {
              'mainContent': {
                controller: 'AvaliacaoNotasFrequenciaController',
                controllerAs: 'vm',
                templateUrl: function(){ return 'views/logged/novas-telas/avaliacao.notas.frequencias.view.html'; }                
              }
            }
          })

        // Calendário
        .state('home.calendarioAulas', {
            cache: false,
            pageId: "horariosaulas",
            url: "/aulas",
            controller: 'HomeController',
            views: {
                'mainContent': {
                    controller: 'AlunoCalendarioAulasController',
                    controllerAs: 'vm',
                    templateUrl: function(){ return 'views/logged/aluno.calendario.aulas.view.html'; }
                }
            }
        })

        // Agenda (Horário de Aula)
        .state('home.agenda', {
            cache: false,
            pageId: "calendario",
            url: "/agenda",
            controller: 'HomeController',
            views: {
                'mainContent': {
                    controller: 'AgendaController',
                    controllerAs: 'vm',
                    templateUrl: function(){ return 'views/logged/aluno.agenda.view.html'; }
                }
            }
        })

        .state('home.agenda-list', {
            cache: false,
            pageId: "calendario",
            url: "/agenda-list",
            controller: 'HomeController',
            views: {
                'mainContent': {
                    controller: 'AgendaListController',
                    controllerAs: 'vm_list',
                    templateUrl: function(){ return 'views/logged/aluno.agenda.list.view.html'; }
                }
            }
        })

        .state('home.agenda-event', {
            cache: false,
            pageId: "calendario",
            url: "/agenda-event",
            controller: 'HomeController',
            views: {
                'mainContent': {
                    controller: 'AgendaEventController',
                    controllerAs: 'vm',
                    templateUrl: function(){ return 'views/logged/aluno.agenda.event.view.html'; }
                }
            }
        })

        // Financeiro
        .state('home.financeiro', {
            cache: false,
            pageId: "boletos",
            url: "/financeiro",
            controller: 'HomeController',
            views: {
                'mainContent': {
                    controller: 'FinanceiroController',
                    controllerAs: 'vm',
                    templateUrl: function(){ return 'views/logged/aluno.financeiro.view.html'; }
                }
            }
        })

        //Solicitacao Serviços
        .state('home.servico-list', {
            cache: false,
            pageId: "servicos",
            url: "/servico-list",
            controller: 'HomeController',
            views: {
                'mainContent': {
                    controller: 'ServicoListController',
                    controllerAs: 'vm_list',
                    templateUrl: function(){ return 'views/logged/aluno.secretaria.servicos.view.html'; }
                }
            }
        })

        .state('home.concluir-solicitacao-servico', {
            cache: false,
            pageId: "servicos",
            url: "/concluir-solicitacao-servico",
            controller: 'HomeController',
            views: {
                'mainContent': {
                    controller: 'ConcluirSolicitacaoServicoController',
                    templateUrl: function(){ return 'views/logged/servico/concluir.solicitacao.servico.view.html'; }
                }
            }
        })

        .state('home.servico-solicitacao', {
            cache: false,
            pageId: "servicos-solicitacao",
            url: "/servico-solicitacao",
            controller: 'HomeController',
            views: {
                'mainContent': {
                    controller: 'ServicoSolicitacaoController',
                    templateUrl: function(){ return 'views/logged/servico/solicitacao.view.html'; }
                }
            }
        })

        .state('home.servico-detail', {
            cache: false,
            pageId: "servico-detail",
            url: "/servico-detail",
            controller: 'HomeController',
            views: {
                'mainContent': {
                    controller: 'ServicoDetailController',
                    templateUrl: function(){ return 'views/logged/servico/solicitacao.detail.view.html'; }
                }
            }
        })

        // Minha Turma
        .state('home.minha-turma-list', {
            cache: false,
            pageId: "minhaturma",
            url: "/minha-turma-list",
            controller: 'HomeController',
            views: {
                'mainContent': {
                    controller: 'AlunoMinhaTurmaController',
                    controllerAs: 'vm',
                    templateUrl: function(){ return 'views/logged/aluno.minha.turma.view.html'; }
                }
            }
        })

        // Frequência/Faltas
        .state('home.frequencia', {
            cache: false,
            pageId: "frequencia",
        	url: "/frequencia",
        	controller: 'HomeController',
        	views: {
        		'mainContent': {
        			controller: 'FrequenciaController',
        			controllerAs: 'vm',
        			templateUrl: function(){ return 'views/logged/aluno.frequencia.view.html'; }
        		}
        	}
        })

        // Ficha Médica
        .state('home.fichamedica', {
            cache: false,
            pageId: "fichamedica",
        	url: "/ficha-medica",
        	controller: 'HomeController',
        	views: {
        		'mainContent': { 
        			controller: 'FichaMedicaController',
        			controllerAs: 'vm',
        			templateUrl: function(){ return 'views/logged/novas-telas/aluno.ficha.medica.view.html'; }
        		}
        	}
        })

        // Publicações
        .state('home.publicacoes', {
            cache: false,
            pageId: "publicacoes",
        	url: "/publicacoes",
            controller: 'HomeController',
            params: {
                disciplina: {},
            },
        	views: {
        		'mainContent': { 
        			controller: 'PublicacoesController',
        			controllerAs: 'vm',
        			templateUrl: function(){ return 'views/logged/novas-telas/publicacoes.disciplinas.view.html'; }
        		}
        	}
        })
        // 6.1 Diario de Classe
        .state('home.diarioclasse', {
            cache: false,
            pageId: "diarioclasse",
        	url: "/diario-classe",
        	controller: 'HomeController',
        	views: {
        		'mainContent': { 
        			controller: 'DiarioClasseController',
        			controllerAs: 'vm',
        			templateUrl: function(){ return 'views/logged/novas-telas/aluno.diario.classe.view.html'; }
        		}
        	}
        })
        // 6.2 Diario de Classe Detalhes
        .state('home.diarioclasse-detalhes', {
            cache: false,
            pageId: "diarioclasse",
            url: "/diario-classe-detalhes",
            controller: 'HomeController',
            params: {
                disciplina: {},
            },
            views: {
                'mainContent': { 
                    controller: 'DiarioClasseDetalhesController',
                    controllerAs: 'vm',
                    templateUrl: function(){ return 'views/logged/novas-telas/aluno.diario.classe.detalhes.view.html'; }
                }
            }
        })

        // 12 Pós Informações tab
        .state('home.pos_infos', {
            pageId: 'pos_infos',
            url: "/pos-informacoes",
            cache: false,
            controller: 'PosInformacoesController',
            views: {
                cache: false,
                'mainContent': {
                    controller: 'PosInformacoesController',
                    templateUrl: function(){ return 'views/logged/novas-telas/aluno.pos.informacoes.view.html'; }
                }
            }
        })

        // 12 tab 1 Ocorrencias
        .state('home.pos_infos.ocorrencias', {
            pageId: 'pos_infos',
            url: "/ocorrencias",
            cache: false,
            controller: 'PosInformacoesController',
            views: {
                cache: false,
                'activityViews': {
                    controller: 'PosInformacoesOcorrenciasController',
                    controllerAs: 'vm',
                    templateUrl: function(){ return 'views/logged/novas-telas/aluno.pos.informacoes.ocorrencias.view.html'; }
                }
            }
        })

        // 12 tab 2 Orientadores
        .state('home.pos_infos.orientadores', {
            pageId: 'pos_infos',
            url: "/orientadores",
            cache: false,
            controller: 'PosInformacoesController',
            views: {
                cache: false,
                'activityViews': {
                    controller: 'PosInformacoesOrientadoresController',
                    controllerAs: 'vm',
                    templateUrl: function(){ return 'views/logged/novas-telas/aluno.pos.informacoes.orientadores.view.html'; }
                }
            }
        })

        // 12 tab 3 Proficiência
        .state('home.pos_infos.proficiencia', {
            pageId: 'pos_infos',
            url: "/proficiencia",
            cache: false,
            controller: 'PosInformacoesController',
            views: {
                cache: false,
                'activityViews': {
                    controller: 'PosInformacoesProficienciaController',
                    controllerAs: 'vm',
                    templateUrl: function(){ return 'views/logged/novas-telas/aluno.pos.informacoes.proficiencia.view.html'; }
                }
            }
        })

        // 12 tab 4 Qualificação
        .state('home.pos_infos.qualificacao', {
            pageId: 'pos_infos',
            url: "/qualificacao",
            cache: false,
            controller: 'PosInformacoesController',
            views: {
                cache: false,
                'activityViews': {
                    controller: 'PosInformacoesQualificacaoController',
                    controllerAs: 'vm',
                    templateUrl: function(){ return 'views/logged/novas-telas/aluno.pos.informacoes.qualificacao.view.html'; }
                }
            }
        })

        // 12 tab 5 Defesa
        .state('home.pos_infos.defesa', { 
            pageId: 'pos_infos',
            url: "/defesa",
            cache: false,
            controller: 'PosInformacoesController',
            views: {
                cache: false,
                'activityViews': {
                    controller: 'PosInformacoesDefesaController',
                    controllerAs: 'vm',
                    templateUrl: function(){ return 'views/logged/novas-telas/aluno.pos.informacoes.defesa.view.html'; }
                }
            }
        })

        // 12 tab 6 Agencias
        .state('home.pos_infos.agencias', { 
            pageId: 'pos_infos',
            url: "/agencias",
            cache: false,
            controller: 'PosInformacoesController',
            views: {
                cache: false,
                'activityViews': {
                    controller: 'PosInformacoesAgenciasController',
                    controllerAs: 'vm',
                    templateUrl: function(){ return 'views/logged/novas-telas/aluno.pos.informacoes.agencias.view.html'; }
                }
            }
        })


        // Tarefas
        .state('home.tarefas', {
            cache: false,
            pageId: "tarefas",
        	url: "/tarefa-list",
        	controller: 'HomeController',
        	views: {
        		'mainContent': {
        			controller: 'TarefaListController',
        			controllerAs: 'vm',
        			templateUrl: function(){ return 'views/logged/aluno.tarefas.view.html'; }
        		}
        	}
        })
                
        // Cobranças
        .state('home.cobranca', {
            cache: false,
            pageId: "cobrancas",
        	url: "/cobranca",
        	controller: 'HomeController',
        	views: {
        		'mainContent': {
        			controller: 'CobrancaController',
        			controllerAs: 'vm',
        			templateUrl: function(){ return 'views/logged/aluno.cobranca.view.html'; }
        		}
        	}
        })
        
        // Nota fiscal eletronica
        .state('home.notafiscal', {
            cache: false,
            pageId: "notafiscal",
        	url: "/nota-fiscal-eletronica",
        	controller: 'HomeController',
        	views: {
        		'mainContent': {
        			controller: 'NotaFiscalController',
        			controllerAs: 'vm',
        			templateUrl: function(){ return 'views/logged/novas-telas/aluno.nota-fiscal.view.html'; }
        		}
        	}
        })

        // Índice de Rendimentos
        .state('home.indicerendimentos', {
            cache: false,
            pageId: "indicerendimentos",
        	url: "/indice-rendimentos",
        	controller: 'HomeController',
        	views: {
        		'mainContent': {
        			controller: 'IndiceRendimentoController',
        			controllerAs: 'vm',
        			templateUrl: function(){ return 'views/logged/novas-telas/aluno.indice-rendimento.view.html'; }
        		}
        	}
        })

        // Cartão de crédito
        .state('home.pgtocartao', {
            cache: false,
            pageId: "pgtocartao",
        	url: "/pgtocartao",
        	controller: 'PagamentoCartaoController',
        	views: {
        		'mainContent': {
        			controller: 'PagamentoCartaoController',
        			controllerAs: 'vm',
        			templateUrl: function(){ return 'views/logged/aluno.pagamento.cartao.view.html'; }
        		}
        	}
        })

        .state('home.pgtocartao.pagamentos', {
            cache: false,
            pageId: "pgtocartao",
        	url: "/pagamentos",
        	views: {
        		'pagamentos': {
                    controller: 'PagamentoCartaoPagamentosController',
                    controllerAs: 'vm',
        			templateUrl: function(){ return 'views/logged/cartao/aluno.pagamento.cartao.pagamentos.view.html'; }
        		}
        	}
        })

        .state('home.pgtocartao.cobrancas', {
            cache: false,
            pageId: "pgtocartao",
        	url: "/cobrancas",
        	views: {
        		'cobrancas': {
                    controller: 'PagamentoCartaoListarController',
                    controllerAs: 'vm',
        			templateUrl: function(){ return 'views/logged/cartao/aluno.pagamento.cartao.listar.view.html'; }
        		}
        	}
        })

        .state('home.pgtocartao-pagamento', {
            cache: false,
            pageId: "pgtocartao",
        	url: "/pagamento",
            controller: 'PagamentoCartaoOrdemController',
        	views: {
        		'mainContent': {
                    controller: 'PagamentoCartaoOrdemController',
                    controllerAs: 'vm',
        			templateUrl: function(){ return 'views/logged/cartao/aluno.pagamento.cartao.ordem.view.html'; }
        		}
        	}
        })

        .state('home.pgtocartao-consulta', {
            cache: false,
            pageId: "pgtocartao",
        	  url: "/consulta/:from/:numPedido",
            controller: 'PagamentoCartaoConsultaController',
        	views: {
        		'mainContent': {
        			controller: 'PagamentoCartaoConsultaController',
        			controllerAs: 'vm',
        			templateUrl: function(){ return 'views/logged/cartao/aluno.pagamento.cartao.consulta.view.html'; }
        		}
        	}
        })

        .state('home.pgtocobrancas', {
            cache: false,
            pageId: "pgtocobrancas",
        	url: "/pgtocobrancas",
        	controller: 'PagamentoCobrancasController',
        	views: {
        		'mainContent': {
        			controller: 'PagamentoCobrancasController',
        			controllerAs: 'vm',
        			templateUrl: function(){ return 'views/logged/aluno.pagamento.cobrancas.view.html'; }
        		}
        	}
        })

        .state('home.pgtocobrancas.pagamentos', {
            cache: false,
            pageId: "pgtocobrancas",
        	url: "/pagamentos",
        	views: {
        		'pagamentos': {
                    controller: 'PagamentoCartaoPagamentosController',
                    controllerAs: 'vm',
        			templateUrl: function(){ return 'views/logged/cartao/aluno.pagamento.cartao.pagamentos.view.html'; }
        		}
        	}
        })

        .state('home.pgtocobrancas.cobrancas', {
            cache: false,
            pageId: "pgtocobrancas",
        	url: "/lista-cobrancas",
        	views: {
        		'cobrancas': {
                    controller: 'PagamentoCobrancasListarController',
                    controllerAs: 'vm',
        			templateUrl: function(){ return 'views/logged/pagamentocobrancas/aluno.pagamento.cobrancas.listar.view.html'; }
        		}
        	}
        })

        .state('home.matricula', {
            cache: false,
            pageId: "matricula",
        	url: "/matricula",
        	views: {
        		'mainContent': {
        			controller: 'MatriculaController',
        			controllerAs: 'vm',
        			templateUrl: function(){ return 'views/logged/aluno.matricula.view.html'; }
        		}
        	}
        })

        .state('home.matricula.termo', {
            cache: false,
            pageId: "matricula",
        	url: "/termo",
            controller: 'MatriculaTermoController',
        	views: {
        		'termo': {
        			controller: 'MatriculaTermoController',
        			controllerAs: 'vm',
        			templateUrl: function(){ return 'views/logged/matricula/aluno.matricula.termo.view.html'; }
        		}
        	}
        })

        .state('home.matricula.cadastro', {
            cache: false,
            pageId: "matricula",
            url: "/dados-cadastrais",
            controller: 'AlunoCadastroController',
            views: {
                'cadastro': {
                    controller: 'AlunoCadastroController',
                    controllerAs: 'vm',
                    templateUrl: function(){ return 'views/logged/matricula/aluno.matricula.dados.cadastrais.view.html'; }
                }
            }
        })

        .state('home.matricula.disciplinas', {
            cache: false,
            pageId: "matricula",
        	url: "/disciplinas",
            controller: 'MatriculaDisciplinasController',
        	views: {
        		'disciplinas': {
        			controller: 'MatriculaDisciplinasController',
        			controllerAs: 'vm',
        			templateUrl: function(){ return 'views/logged/matricula/aluno.matricula.disciplinas.view.html'; }
        		}
        	}
        })

        .state('home.matricula.contrato', {
            cache: false,
            pageId: "matricula",
        	url: "/contrato",
            controller: 'MatriculaContratoController',
        	views: {
        		'contrato': {
        			controller: 'MatriculaContratoController',
        			controllerAs: 'vm',
        			templateUrl: function(){ return 'views/logged/matricula/aluno.matricula.contrato.view.html'; }
        		}
        	}
        })

        .state('home.matricula.requerimento', {
            cache: false,
            pageId: "matricula",
        	url: "/requerimento",
            controller: 'MatriculaRequerimentoController',
        	views: {
        		'requerimento': {
        			controller: 'MatriculaRequerimentoController',
        			controllerAs: 'vm',
        			templateUrl: function(){ return 'views/logged/matricula/aluno.matricula.requerimento.view.html'; }
        		}
        	}
        })

        // Contratos
        .state('home.contrato', {
            cache: false,
            pageId: "contrato",
            url: "/contrato",
            controller: 'HomeController',
            views: {
                'mainContent': {
                    controller: 'ContratoController',
                    controllerAs: 'vm',
                    templateUrl: function(){ return 'views/logged/aluno.contrato.view.html'; }
                }
            }
        })

        // Acordo
        .state('home.acordo', {
            pageId: 'acordo',
            url: "/acordos",
            cache: false,
            controller: 'AcordoController',
            params: {
                isContrato: null
            },
            views: {
            	cache: false,
                'mainContent': {
                    controller: 'AcordoController',
                    controllerAs: 'acordoCtrl',
                    templateUrl: function(){ return 'views/logged/aluno.acordo.view.html'; }
                },
                '': {
                    controller: 'AcordoController',
                    controllerAs: 'acordoCtrl',
                    templateUrl: function(){ return 'views/logged/aluno.acordo.view.html'; }
                }
            }
        })

        .state('home.acordo.cadastro', {
            pageId: 'acordo',
            url: "/cadastro",
            cache: false,
            views: {
                cache: false,
                'cadastroAcordo': {
                    controller: 'AcordoCadastroController',
                    controllerAs: 'acordoCadCtrl',
                    templateUrl: function(){ return 'js/custom-components/acordo-cadastro/acordo.cadastro.html'; }
                }
            }
        })

        .state('home.acordo.cadastro.cobrancas', {
            cache: false,
            pageId: 'acordo',
            url: "/cobrancas",
            views: {
                'cobrancas': {
                    templateUrl: function(){ return 'js/custom-components/acordo-cadastro/wizard/acordo.cadastro.cobrancas.html'; }
                }
            }
        })

        .state('home.acordo.cadastro.parcelamentos', {
            cache: false,
            pageId: 'acordo',
            url: "/parcelamentos",
            views: {
                'parcelamentos': {
                    templateUrl: function(){ return 'js/custom-components/acordo-cadastro/wizard/acordo.cadastro.parcelamentos.html'; }
                }
            }
        })

        .state('home.acordo.cadastro.confirmacao', {
            cache: false,
            pageId: 'acordo',
            url: "/confirmacao",
            views: {
                'confirmacao': {
                    templateUrl: function(){ return 'js/custom-components/acordo-cadastro/wizard/acordo.cadastro.confirmacao.html'; }
                }
            }
        })

        .state('home.acordo.cadastro.contrato', {
            cache: false,
            pageId: 'acordo',
            url: "/contrato",
            views: {
                'contrato': {
                    templateUrl: function(){ return 'js/custom-components/acordo-cadastro/wizard/acordo.cadastro.contrato.html'; }
                }
            }
        })

        .state('home.acordo.consulta', {
            pageId: 'acordo',
            url: "/consulta",
            cache: false,
            params: {
                codAcordo: null
            },
            views: {
                cache: false,
                'consultaAcordo': {
                    controller: 'AcordoConsultaController',
                    controllerAs: 'acordoConCtrl',
                    templateUrl: function(){ return 'js/custom-components/acordo-consulta/acordo.consulta.html'; }
                }
            }
        })

        .state('home.historico', {
            pageId: 'historico',
            url: "/historico",
            cache: false,
            controller: 'AtividadesController',
            views: {
                cache: false,
                'mainContent': {
                    controller: 'AtividadesController',
                    controllerAs: 'historicoCtrl',
                    templateUrl: function(){ return 'views/logged/aluno.atividades.view.html'; }
                }
            }
        })

        .state('home.historico.home', {
            pageId: 'historico',
            url: "",
            cache: false,
            controller: 'AtividadesController',
            views: {
                cache: false,
                'activityViews': {
                    controller: 'HistoricoController',
                    controllerAs: 'historicoCtrl',
                    templateUrl: function(){ return 'views/logged/aluno.historico.view.html'; }
                }
            }
        })

        .state('home.historico.atividades-complementares', {
            pageId: 'historico',
            url: "/atividades-complementares",
            cache: false,
            controller: 'AtividadesController',
            views: {
                cache: false,
                'activityViews': {
                    controller: 'AtividadesComplementaresController',
                    controllerAs: 'vm',
                    templateUrl: function(){ return 'views/logged/novas-telas/aluno.atividades.complementares.view.html'; }
                }
            }
        })

        .state('home.historico.exigiveis-por-tipo', {
            pageId: 'historico',
            url: "/exigiveis-por-tipo",
            cache: false,
            controller: 'AtividadesController',
            views: {
                cache: false,
                'activityViews': {
                    controller: 'AtividadesExigiveisPorTipoController',
                    controllerAs: 'vm',
                    templateUrl: function(){ return 'views/logged/novas-telas/aluno.atividades.complementares.exigiveis.view.html'; }
                }
            }
        })

        // Trabalhos
        .state('home.trabalhos', {
            pageId: 'trabalhos',
            url: "/trabalhos",
            cache: false,
            controller: 'TrabalhosController',
            views: {
                cache: false,
                'mainContent': {
                    controller: 'TrabalhosController',
                    controllerAs: 'trabalhosCtrl',
                    templateUrl: function(){ return 'views/logged/novas-telas/aluno.trabalhos.view.html'; }
                }
            }
        })

        // Trabalhos Submissão
        .state('home.trabalhos.submissao', {
            pageId: 'trabalhos',
            url: "/submissao",
            cache: false,
            controller: 'TrabalhosController',
            views: {
                cache: false,
                'activityViews': {
                    controller: 'TrabalhosSubmissaoController',
                    controllerAs: 'vm',
                    templateUrl: function(){ return 'views/logged/novas-telas/aluno.trabalhos.submissao.view.html'; }
                }
            }
        })

        // Trabalhos Histórico
        .state('home.trabalhos.historico', {
            pageId: 'trabalhos',
            url: "/historico",
            cache: false,
            controller: 'TrabalhosController',
            views: {
                cache: false,
                'activityViews': {
                    controller: 'TrabalhosHistoricoController',
                    controllerAs: 'vm',
                    templateUrl: function(){ return 'views/logged/novas-telas/aluno.trabalhos.historicos.view.html'; }
                }
            }
        })
        
        // Processo Seletivo
        .state('home.processoseletivo', {
            pageId: 'processoseletivo',
            url: "/processo-seletivo",
            cache: false,
            controller: 'ProcessoSeletivoController',
            views: {
                cache: false,
                'mainContent': {
                    controller: 'ProcessoSeletivoController',
                    controllerAs: 'vm',
                    templateUrl: function(){ return 'views/logged/novas-telas/aluno.processo-seletivo.view.html'; }
                }
            }
        })

        // Trabalhos Conclusão de Curso
        .state('home.pos_tcc', {
            pageId: 'pos_tcc',
            url: "/conclusao-de-curso",
            cache: false,
            controller: 'TrabalhosConclusaoController',
            views: {
                cache: false,
                'mainContent': {
                    controller: 'TrabalhosConclusaoController',
                    controllerAs: 'vm',
                    templateUrl: function(){ return 'views/logged/novas-telas/aluno.trabalhos.conclusao.view.html'; }
                }
            }
        })

        // Trabalhos Conclusão de Curso Editar
        .state('home.pos_tcc-editar', {
            pageId: 'pos_tcc',
            url: "/conclusao-de-curso/editar",
            cache: false,
            controller: 'TrabalhosConclusaoEditarController',
            views: {
                cache: false,
                'mainContent': {
                    controller: 'TrabalhosConclusaoEditarController',
                    controllerAs: 'vm',
                    templateUrl: function(){ return 'views/logged/novas-telas/aluno.trabalhos.editar.view.html'; }
                }
            }
        })
        
        // Trabalhos Conclusão de Curso Dados
        .state('home.pos_tcc.dados', {
            pageId: 'pos_tcc',
            url: "/dados",
            cache: false,
            controller: 'TrabalhosConclusaoController',
            views: {
                cache: false,
                'activityViews': {
                    controller: 'TrabalhosConclusaoDadosController',
                    controllerAs: 'vm',
                    templateUrl: function(){ return 'views/logged/novas-telas/aluno.conclusao.dados.view.html'; }
                }
            }
        })

        // Trabalhos Conclusão de Curso Patrocinadores
        .state('home.pos_tcc.patrocinadores', {
            pageId: 'pos_tcc',
            url: "/patrocinadores",
            cache: false,
            controller: 'TrabalhosConclusaoController',
            views: {
                cache: false,
                'activityViews': {
                    controller: 'TrabalhosConclusaoPatrocinadoresController',
                    controllerAs: 'vm',
                    templateUrl: function(){ return 'views/logged/novas-telas/aluno.conclusao.patrocinadores.view.html'; }
                }
            }
        })

        // Trabalhos Conclusão de Curso Envio de Arquivos
        .state('home.pos_tcc.envio-de-arquivos', {
            pageId: 'pos_tcc',
            url: "/envio-de-arquivos",
            cache: false,
            controller: 'TrabalhosConclusaoController',
            params: {
                urlTcc: false,
            },
            views: {
                cache: false,
                'activityViews': {
                    controller: 'TrabalhosConclusaoEnvioArquivosController',
                    controllerAs: 'vm',
                    templateUrl: function(){ return 'views/logged/novas-telas/aluno.conclusao.envio-de-arquivos.view.html'; }
                }
            }
        })
        // Fim POS TCC

        .state('home.pages', {
            cache: false,
          url: "/{name:.*}",
          controller: 'PageController',
          views: { 'menuContent': {
	                    templateUrl: function(urlattr) {
	                    $log.debug('urlattr=', urlattr);

	                    return 'views/'+urlattr.name+'.view.html'; }
	               }
          		 }
        })

        .state('405', {
            url: "/error/405",
            controller: 'PageController',
            templateUrl: function(){ return 'views/error/405.view.html'; }

        })

        .state('404', {
            url: "/error/404",
            controller: 'PageController',
            templateUrl: function(){ return 'views/error/404.view.html'; }

        })

        .state('403', {
            url: "/error/403",
            controller: 'PageController',
            templateUrl: function(urlattr) {
                return 'views/error/403.view.html';
            }
        });

        $urlRouterProvider

        /**
         * Rota de acesso à aplicação com usuário e senha em Base64
         * evitando a apresentação do login form.
         */

        .when('/autologin?a&b', [ '$rootScope', '$state', '$location', 'sharedLoginService', 'Storage',
          function ($rootScope, $state, $location, sharedLoginService, Storage) {

            let username = $location.$$search.a;
            let password = $location.$$search.b;
            let pagina = $location.$$search.c;
            let paramsPgto = $location.$$search.d;
			let url = $location.$$search.url;

          	if (!angular.equals($location.path(), "/resetsenha") && (!username || !password)) {
              $location.path("/login");
            }
            else {
              username=window.atob(username);
              password=window.atob(password);
              
              if (paramsPgto) {
                paramsPgto= window.atob(paramsPgto);
                  Storage.set("paramsPgto", paramsPgto);
              }

              if (pagina){
                pagina=window.atob(pagina);
                  Storage.set("autoLogin", pagina);
              }

          	  var dataset = {};

              dataset.user = {};
              dataset.user.username = username;
              dataset.user.password = password;
              dataset.user.pagina = pagina;
			  dataset.url = url;
              dataset.message = {};
          	  dataset.profile = {};
              dataset.login = sharedLoginService.login;
              dataset.selectProfile = sharedLoginService.selectProfile;
              dataset.selectProfile('aluno');

              $rootScope.infiniteReached = function() {};

              sharedLoginService.set(dataset);

              dataset.login();
          	}
          }
        ])

        .when('', ['$state','$match',
          function ($state, $match) {
            $state.go('home.avisos');
          }
        ])

        .when('/', ['$state','$match',
          function ($state, $match) {
            $state.go('home.avisos');
          }
        ])

        .otherwise(
          function($injector, $location) {
            var $state = $injector.get('$state');
            $state.go('404');
          }
        );
    })

    .factory('customTranslateLoader', function ($http, $q) {
        return function (options) {
          if (!options || (!angular.isArray(options.files) && (!angular.isString(options.prefix) || !angular.isString(options.suffix)))) {
            throw new Error('Couldn\'t load static files, no files and prefix or suffix specified!');
          }
          if (!options.files) {
            options.files = [{
              prefix: options.prefix,
              suffix: options.suffix
            }];
          }
          var load = function (file) {
            if (!file || (!angular.isString(file.prefix) || !angular.isString(file.suffix))) {
              throw new Error('Couldn\'t load static file, no prefix or suffix specified!');
            }
            var deferred = $q.defer();
            $http(angular.extend({
              url: [
                file.prefix,
                options.key,
                file.suffix
              ].join(''),
              method: 'GET',
              params: ''
            }, options.$http)).success(function (data) {
              deferred.resolve(data);
            }).error(function () {
              deferred.resolve({});
            });
            return deferred.promise;
          };
          var deferred = $q.defer(),
              promises = [],
              length = options.files.length;
          for (var i = 0; i < length; i++) {
            promises.push(load({
              prefix: options.files[i].prefix,
              key: options.key,
              suffix: options.files[i].suffix
            }));
          }
          $q.all(promises).then(function (data) {
            var length = data.length,
                mergedData = {};
            for (var i = 0; i < length; i++) {
              for (var key in data[i]) {
                mergedData[key] = data[i][key];
              }
            }
            deferred.resolve(mergedData);
          }, function (data) {
            deferred.reject(data);
          });
          return deferred.promise;
        };
      })

    .config(
        function($translateProvider, tmhDynamicLocaleProvider) {
            $translateProvider.uniformLanguageTag('bcp47');
            $translateProvider.fallbackLanguage('pt_br');
            $translateProvider.preferredLanguage('pt_br');

            $translateProvider.useMissingTranslationHandlerLog();

            $translateProvider.useLoader('customTranslateLoader', {
                files: [
                    {
                        prefix: 'i18n/locale_',
                        suffix: '.json'
                    }
                ]
            });
            $translateProvider
                .translations('pt', { /* ... */ })
                .translations('en', { /* ... */ })
                .registerAvailableLanguageKeys(
                ['pt_br', 'en_us'], {
                'en*': 'en_us',
                'pt*': 'pt_br',
                '*': 'pt_br'
            })
            .determinePreferredLanguage();

            var locale = (window.navigator.userLanguage || window.navigator.language || 'pt_br').replace('-', '_').toLowerCase();
            $translateProvider.use(locale);

            $translateProvider.useLocalStorage();
            $translateProvider.determinePreferredLanguage();
            $translateProvider.useSanitizeValueStrategy('escaped');

            let localePlugin = 'node_modules/angular-i18n/angular-locale_{{locale}}.js';
            tmhDynamicLocaleProvider.localeLocationPattern(localePlugin);
        }
     )

    .directive('crnValue', ['$parse', function($parse) {
      return {
        restrict: 'A',
        require: '^ngModel',
        link: function(scope, element, attr, ngModel) {
            var evaluatedValue;
            if(attr.value) {
              evaluatedValue = attr.value;
            } else {
              evaluatedValue = $parse(attr.crnValue)(scope);
            }
            element.attr("data-evaluated", JSON.stringify(evaluatedValue));
            element.bind("click", function(event) {
              scope.$apply(function() {
                 ngModel.$setViewValue(evaluatedValue);
              }.bind(element));
            });
        }
      };
    }])

    // General controller
    .controller(
        'PageController', [
            "$scope",
            "$stateParams",
            "$location",
            "$http",
            function($scope, $stateParams, $location, $http) {
                var ctrl = this;

                ctrl.id = 'PageController';
                $scope.id = ctrl.id;

                // for (var x in app.userEvents)
                //     $scope[x] = app.userEvents[x].bind($scope);

                pageController = this;

                $scope.params = $stateParams;
                $scope.$http  = $http;

                var queryStringParams = $location.search();

                for (var key in queryStringParams) {
                    if (queryStringParams.hasOwnProperty(key)) {
                        $scope.params[key] = queryStringParams[key];
                    }
                }
            }
        ])

        .run(['$log', '$rootScope', '$location', '$state', 'SessionData', 'UserService', 'Storage', 'HttpPendingRequestService', '$ionicSideMenuDelegate', 'NeoAssistService', '$anchorScroll', 'Notification',
          function($log, $rootScope, $location, $state, SessionData, UserService, Storage, HttpPendingRequestService, $ionicSideMenuDelegate, NeoAssistService, $anchorScroll, Notification) {

            $anchorScroll.yOffset = 100;

            $rootScope.$on('$stateChangeError',
              function(event, toState, toParams, fromState, fromParams, error) {
            	  event.preventDefault();

              	if (error === 403 || error === 404 || error === 405) {
                  $state.go(error);
                }
              	else {
                  $log.error('[$stateChangeError] ' + error);
                }
              }
            ),

            $rootScope.$on('$stateChangeStart',
              function(event, toState, toParams, fromState, fromParams, options) {

                    if ($(".modal-backdrop")) {
                        $(".modal-backdrop").remove();
                        $("body").removeClass("modal-open");
                    }

                    if (fromState.pageId == "aluno-extrato" && toState.pageId != "aluno-extrato") {
                        $("#ui-datepicker-div").hide();
                    }

                    if (fromState.name == "home.boletim.resumo" && toState.name != "home.boletim.resumo") {

                      $ionicSideMenuDelegate.canDragContent(true);
                    }

                    var isLoginState = function(){
                        return toState && toState.name && toState.name === 'login';
                    }

                    var isResetSenhaState = function(){
                        return toState && toState.name && ( toState.name === 'recuperarSenha' || toState.name === 'recuperarSenhaToken' );
                    }

                    var isAceiteContratoState = function(){
                        return toState && toState.name && toState.name === 'home.aceiteContrato';
                    };

                    // Verifica se o aluno tem acesso à página requisitada
                    var isAuthorizedAccessToPage = function(toState){
                        let name = toState.name;
                        let pageID = toState.pageId;
                        let url = toState.url;
                        let accessOk = false;

                        // Lista de Paginas liberadas
                        let releasedPages = [
                                                'login', 'recuperarSenha', 'recuperarSenhaToken', 'redefinirSenha', 'home',
                                                'home.loading', 'home.responsavel', 'home.componentes',
                                                'home.aceiteContrato', 'home.pages', '403', '404', '405'
                                            ];

                        if(releasedPages.indexOf(name) > -1){
                            accessOk = true;
                        } else {
                            let aluno = !SessionData.isEmpty('aluno') ? SessionData.aluno : null;
                            let permittedPages = (aluno && aluno.list[0].permittedPages) ? aluno.list[0].permittedPages : null;
                            name = name.replace('home.', '');

                            $log.debug("state.name: ", name);

                            if(permittedPages && permittedPages.length > 0){
                                let isValidAccess = function(page){
                                    if(name.startsWith(page)){
                                        $log.debug("state.name: " + name + " - page: " + page);
                                        accessOk = true;
                                    }
                                };

                                permittedPages.forEach(isValidAccess);
                            } else {
                                accessOk = true;
                            }
                        }


                        return accessOk;
                    };


                    if (!isLoginState() && !isResetSenhaState()) {
                      if (SessionData.isEmpty('user') || SessionData.isEmpty('aluno')) {
                        event.preventDefault();
                        $state.go('login');
                      } else {

                            if (SessionData.user.senhaAlterada === "false"){
                                event.preventDefault();
                                $state.go('recuperarSenha');
                            }

                            if (Storage.get("questionariosObrigatorios") && toState.pageId != undefined && toState.pageId !== 'avaliacaoinstitucional'){
                                event.preventDefault();
                                Notification.error('Favor, responder o(s) questionário(s) obrigatório(s).');
                                $state.go('home.avaliacaoinstitucional');
                            }

                            if (Storage.get("exibirContrato") && !isAceiteContratoState()) {
                                event.preventDefault();
                                $state.go('home.aceiteContrato');
                            }

                            if (toState && toState.name && fromState && fromState.name && toState.pageId !== fromState.pageId) {
                                /*
                                * Numa mudança de $state já autenticado, cancela requests pendentes do $state anterior.
                                * Com o uso de um loading mask ($ionicLoading) esta pendência é improvável, mesmo assim,
                                * utilizar este mecanismo aqui garante que a lista de promises canceláveis do serviço
                                * HttpPendingRequestService fique restrita a um tamanho mínimo.
                                */
                                HttpPendingRequestService.cancelAll();
                            }

                            // Verifica se o aluno tem acesso à página
                            if(!isAuthorizedAccessToPage(toState)){
                                $log.info('ACESSO NAO AUTORIZADO: ' + toState.pageId + ' : ' + toState.url);
                                event.preventDefault();
                                $state.go('403');
                            }

                            if (ga) {
                            let strNextURL = getNextURL($location, toState);
                            ga('send', 'pageview', {page: strNextURL});
                            }

                            // Carrega/Recarrega o chat NeoAssist e preenche suas variáveis, caso ele esteja implementado.
                            if(SessionData.aluno && SessionData.aluno.list && SessionData.aluno.list.length > 0){
                                let alunoSelecionado = SessionData.aluno.list[SessionData.aluno.selecionado]; 

                                NeoAssistService.obterDadosNeoAssist(alunoSelecionado.codAluno).then(function(dadosNeoAssist){
                                    if(dadosNeoAssist){
                                        let nomeAbrev = dadosNeoAssist.nomeAbreviadoIES ? dadosNeoAssist.nomeAbreviadoIES : '';
                                        NeoAssistService.loadNeoAssistScript(nomeAbrev, dadosNeoAssist); 
                                    }

                                }).catch(function(err){
                                    $log.debug('Erro ao carregar os dados do NeoAssist: ', err);
                                });
                            }
                        }
                    }
              }
            );
        }]);

}(window));

app.userEvents = {};
